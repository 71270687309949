body {
    background-color: transparent;
}

model-viewer#reveal {
    --poster-color: transparent;
}

#app {
    width: 100%;
    height: 100%;
}

.desenio-btn {
    width: 100%; 
    height: 100%; 
    background-color: #DDDDDF;
    transition: background-color 0.2s;
    border: none;
    color: black;
    font-weight: 600;
}

.desenio-btn:hover, .desenio-btn:active {
    background-color: #CFCFCD;
}

.desenio-btn:disabled {
    background-color: #DDDDDF;
    color: #616161;
}

.desenio-btn:not(.loading) .loading-indicator {
    display: none;
}
